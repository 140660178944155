@background: #e6e6e6;

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Firefox */
}

.weekDays-selector input {
    display: none !important;
}

.weekDays-selector input[type=checkbox]+label {
    display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 30px;
    width: 30px;
    margin-right: 8px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked+label {
    background: #304663;
    color: #ffffff;
}

.limited {
    border: 1px solid darken(#e6e6e6, 10%);
    color: darken(#e6e6e6,40%);        
    background: fade(#FFFFFF,80%);
    padding: 0 50px 0 20px;  
    line-height: 40px;
    font-size: 0.875em;
    height: 40px;    
    outline: none;    
    width: 100%;
  }
  .counter {
    color: darken(#e6e6e6,30%);    
    line-height: 40px;
    text-align: center;
    position: absolute;  
    font-size: 0.875em;
    opacity: 0.4;
    height: 100%;
    width: 50px;
    right: 0; 
  }
  
  .limited:hover + .counter,
  .limited:focus + .counter {
    opacity: 1;
  }
  
  .is-over {
    border: 1px solid lighten(#C26464,20%); 
    background: fade(#C26464,5%);
    color: fade(#C26464,85%);   
  }